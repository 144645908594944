import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentWrapper from "../../components/Shared/ContentWrapper"
import ContentSection from "../../components/Shared/ContentSection"
import BlogNav from "../../components/Blog/BlogNav"
import BlogPostMedia from "../../components/Blog/BlogPostMediaPreview"
import ThesisProjectNavigation from "../../components/ThesisProject/ThesisProjectNavigation"
import PostAuthorSection from "../../components/Shared/PostAuthorSection"

const OcadUndergradThesisPage1 = ({ data, uri }) => {
  const postData = {
    id: "ocadutp01",
    postType: "Default",
    title: "Orrery Design: Overview",
    slug: "/ocad-undergrad-thesis",
    createdAt: "Jan 10, 2020",
    categories: [
      {
        name: "Coding",
        slug: "coding",
        id: "3YN1fgeDhbqNHSGzcUZuUE",
      },
      {
        name: "Web",
        slug: "web",
        id: "6rlTGOLIAdgMKRsTS5zPaP",
      },
      {
        name: "Mobile",
        slug: "mobile",
        id: "3GUFF463ocF1ZISKLUu2l",
      },
    ],
    featuredImage: {
      file: {
        url: "https://images.ctfassets.net/z5m1dcunobst/YBJQZKR0UMXmLWyuILuoz/181dc25335d4d876309a3db2660bdd17/Orreretically_Speaking_4_16_2020_5_46_24_AM.png",
      },
    },
  }

  return (
    <Layout
      title={postData.title}
      scrollImage={postData.featuredImage.file.url}
    >
      <SEO title={postData.title} />

      <ContentWrapper
        nav={
          <BlogNav
            backButton={
              <Link to="/blog" className="ajax back-to-home-btn">
                <span>Back to Blog</span>
              </Link>
            }
          />
        }
      >
        <ContentSection
          id="sec1"
          // description={`by Jerez Bain on ${postData.createdAt}`}
          decoratorPosition="top"
          separator
          sectionClasses="hidden-section bot-element text-left"
        >
          <div className="section-title fl-wrap post-title">
            <h3>{postData.title}</h3>
            <div className="post-header">
              <a href="#">{postData.createdAt}</a>
              <span>Category : </span>
              {postData.categories.map(category => (
                <Link
                  key={category.id}
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </div>

          <BlogPostMedia post={postData} />

          <ThesisProjectNavigation />

          <div className="post-block fl-wrap">
            <div className="post-opt fl-wrap">
              <ul>
                <li>
                  <a href="#">
                    <i className="fal fa-user" /> Jerez Bain
                  </a>
                </li>
                {/*<li>
                  <a href="#">
                    <i className="fal fa-comments-alt" />{" "}
                    <CommentCount config={disqusConfig} placeholder={"..."} />
                  </a>
                </li>*/}
                {/*<li>
                  <span>
                    <i className="fal fa-eye" /> 123 view
                  </span>
                </li>*/}
              </ul>
            </div>
            <div>
              <p className="text-center">
                An orrery is a mechanical model of the solar system that shows
                the relative positions and motions of the planets and moons
                according to the heliocentric (sun-centered) model. Having the
                ability to tune it to a specific date, I hope to provide a
                celestial perspective of what that day looks like, and augment
                our understanding of time.
              </p>

              <hr />

              <h2 className="text-center">3 Ways to Experience It </h2>

              <div className="row">
                <div className="col">
                  <a>
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/6cWUvQzhygZYpJ3K5momyE/6584bb8060f13033d5c9e029c31ee22b/noun-pc-download-662019__1_.png"
                      alt="Download the Virtual Installation"
                    />
                  </a>
                  <p className="text-center">
                    Download it to get the fully functional experience on your
                    computer:
                    <br />
                    <a
                      href="https://developer.cloud.unity3d.com/share/share.html?shareId=b1YSP26F3H"
                      data-link-type="external"
                      data-link-value="https://developer.cloud.unity3d.com/share/share.html?shareId=b1YSP26F3H"
                      target="_blank"
                    >
                      WINDOWS
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://developer.cloud.unity3d.com/share/share.html?shareId=Z14S-qpF3B"
                      data-link-type="external"
                      data-link-value="https://developer.cloud.unity3d.com/share/share.html?shareId=Z14S-qpF3B"
                      target="_blank"
                    >
                      MAC
                    </a>
                  </p>
                </div>
                <div className="col">
                  <a
                    href="https://d1zxzsf6hv9kvj.cloudfront.net/"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/7aC5xIcEtF4NWvCgROMAR9/31408a77068f62baaac5e4c8a28cfcc9/noun-browser-game-616242.png"
                      alt="View the installation in your web browser."
                    />
                  </a>
                  <p className="text-center">
                    Experience it in your{" "}
                    <a
                      href="https://d1zxzsf6hv9kvj.cloudfront.net/"
                      data-link-type="external"
                      data-link-value="https://d1zxzsf6hv9kvj.cloudfront.net/"
                      target="_blank"
                    >
                      web browser
                    </a>
                    . <br />
                    (This may take some time to load depending on your
                    connection speed...)
                  </p>
                </div>
                <div className="col">
                  <a href="/process-gallery">
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/60dGKFNoooEYeXUHtrToko/bd9a903de66bc3ebf7017d9047d58293/noun-browser-play-616260.png"
                      alt="Youtube videos of the installation"
                    />
                  </a>

                  <p className="text-center">
                    View media in the{" "}
                    <a
                      href="/7246606"
                      data-link-type="internal"
                      data-link-value="7246606"
                    >
                      gallery
                    </a>
                    .
                  </p>
                </div>
              </div>

              <h2 className="text-center">Time in Context</h2>

              <p className="text-center">
                I am interested in exploring, through this thesis project, how
                time can be presented in an unfamiliar display that can become
                an association of a memory or an anticipated event in the
                future. I am building an orrery (model solar system) that relies
                on data gathered though astrometry to allow viewers to see what
                the solar system looked like on a given day.
              </p>

              <div className="row pb-4">
                <div className="col">
                  <a
                    href="https://www.humanssince1982.com/a-million-times-at-changi"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/7g762fz4VbTRhduNng6fB9/b5da5606f8ed4c0b8d7e68f4b99100b4/A_Million_Times_Changi_23_59.png"
                      alt="A Million Times at Changi"
                    />
                  </a>
                </div>
                <div className="col">
                  <h3>A Million Times at Changi</h3>
                  <p>
                    I am interested in how contextual time is precious to the
                    individual. In terms of memories, all the information that
                    our five senses experienced becomes the substance of context
                    within that memory. For better or worse, these memories
                    influence many aspects of our lives, even sometimes
                    unknowingly.&nbsp;
                  </p>
                  <p>
                    In their artist statement for the “
                    <a
                      href="https://www.humanssince1982.com/a-million-times-at-changi"
                      data-link-type="external"
                      data-link-value="https://www.humanssince1982.com/a-million-times-at-changi"
                      target="_blank"
                    >
                      A Million Times at Changi
                    </a>
                    ” installation,&nbsp;
                    <a
                      href="https://www.humanssince1982.com/"
                      data-link-type="external"
                      data-link-value="https://www.humanssince1982.com/"
                      target="_blank"
                    >
                      Bischoff, Bastian, and Per&nbsp;Emanuelsson
                    </a>
                    , elaborate on the crippling reality of how dependent on
                    time we are. the question that shadowed them throughout
                    their studies was, ‘“How time designs and how to design
                    time?”‘&nbsp;Certainly, there are different contexts of time
                    that fill our lives and we&nbsp;deem more important than
                    others such as, food delivery ETAs, work and class due
                    dates; appointments and meetings; pregnancy; and even the
                    fated end of life. Their work shares a similar goal that I
                    aim to contribute to – how to design time.&nbsp;
                  </p>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col">
                  <h3>Story</h3>
                  <p>
                    <a
                      href="https://flytestore.com"
                      data-link-type="external"
                      data-link-value="https://flytestore.com"
                      target="_blank"
                    >
                      Flyte
                    </a>
                    ’s product, '
                    <a
                      href="https://flytestore.com/pages/story-levitating-timepiece"
                      data-link-type="external"
                      data-link-value="https://flytestore.com/pages/story-levitating-timepiece"
                      target="_blank"
                    >
                      STORY
                    </a>
                    ’, touches on the more contextual aspect of time that I am
                    exploring. STORY is&nbsp;a modern wall clock that without
                    hands. It has an LED display for the current time and a
                    metal ball that moves around the clock&nbsp;face in the
                    duration of a ‘journey’. A ‘journey’ is illustrated as a
                    complete clockwise rotation of the ball around the clock
                    face from position 12 to 12 again. Among other things, the
                    journey’s duration is completely customizable.&nbsp;
                  </p>
                </div>
                <div className="col">
                  <a
                    href="https://flytestore.com/pages/story-levitating-timepiece"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/3mksZ5QbdBtvrBbq10bKfX/904d7f12d87117d45398032e6e14aade/FLYTE-STORY_1024x1024.jpg"
                      alt="STORY by FLYTE"
                    />
                  </a>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col">
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/znJsCWjfRZ0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="col">
                  <h3>Orrery at Jodrell Bank Discovery Centre</h3>
                  <p>
                    The&nbsp;
                    <a
                      href="http://www.jb.man.ac.uk/news/2013/Orrery/"
                      data-link-type="external"
                      data-link-value="http://www.jb.man.ac.uk/news/2013/Orrery/"
                      target="_blank"
                    >
                      Orrery at Jodrell Bank Discovery Centre
                    </a>
                    &nbsp;boasts the precision that I strive to replicate in my
                    prototype. It uses a single planetary gear motor that drives
                    a system of 52 gears in tandem to synchronize&nbsp;the orbit
                    of the planets. Similar to&nbsp;all mechanically driven
                    orreries, the passage of time is expressed in the beauty of
                    orbital harmony. What I hope to achieve with my prototype is
                    not only this, but also the beauty of this harmony for a
                    specified moment in time.
                  </p>
                </div>
              </div>

              <div className="row py-5">
                <div className="col">
                  <p className="text-center">
                    To fulfill what I see as my role in exploring the design of
                    contextual time,&nbsp;
                  </p>
                  <p className="text-center">
                    the orrery will&nbsp;have several features that separate it
                    from traditional mechanical orreries.&nbsp;&nbsp;
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col col-4">
                  <a>
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/11W5V0YJt5FjNEDkjg2al1/66a68d49021d403a85dd68043496e082/noun_solar_system_2322289.png"
                    />
                  </a>
                </div>
                <div className="col">
                  <h3>What makes my prototype&nbsp;different?</h3>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <b>Independent Orbits&nbsp;</b>-&nbsp;I will not be using
                      a single system of gears&nbsp;in tandem for the entire
                      installation. Each planet will operate move independently
                      of each other.
                    </li>
                    <li className="list-group-item">
                      <b>Toggled Behaviour</b>&nbsp;-&nbsp;There will be two
                      modes available: the demo Mode,&nbsp;which will behave
                      like a regular mechanical orrery;&nbsp;and&nbsp;
                      the&nbsp;Epoch Mode, which will allow users to enter a
                      date causing the installation&nbsp;to move the planets
                      into their&nbsp;position based on the date.
                    </li>
                    <li className="list-group-item">
                      <b>Symbols not Spheres</b>&nbsp;-&nbsp;Instead of using
                      spheres, I would like to explore a more artistic approach
                      with astronomical&nbsp;symbols which represent the
                      planets.
                    </li>
                    <li className="list-group-item">
                      <b>Future-Proof&nbsp;</b>- The use of VSOP to calculate
                      positions of planets affords my project a level of
                      precision that stands the test of time (pun
                      intended).&nbsp; Unlike mechanical orreries, that only
                      replicate orbital synchronization, my project has the data
                      required to replicate true orbits along 3 dimensions. In
                      this current iteration of my prototype, I am not utilizing
                      all of this data, but with time and resources it can be
                      achieved.
                    </li>
                  </ul>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col">
                  <h2 className="text-center">Process & Development</h2>
                  <p className="text-center">
                    The Iterative Design Process has enabled me to make
                    incremental progress on my thesis project.&nbsp;
                    <br />
                    Many parts have been involved in creating the orrery, that i
                    have summarized below, and can be explored in greater detail
                    in the links below.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Link to="/ocad-undergrad-thesis/interface">
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/1dz5CLyLt7tsX8Xc89OXT4/7451a85d8a01294985b2d413c0855f16/Screen_Shot_2020-03-13_at_11_01_25_AM.png"
                    />
                  </Link>
                  <h2 className="text-center xl-headline">
                    <Link to="/ocad-undergrad-thesis/interface">Interface</Link>
                  </h2>
                  <p>
                    <br />
                  </p>
                </div>
                <div className="col">
                  <Link to="/ocad-undergrad-thesis/orrery">
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/1H8I4bRWCiB4nnaXdbqH78/22bb36f8ba3a7f9caf9c61cda7f6a93d/Track_2_2019-Oct-25_08-25-52AM-000_CustomizedView35195775055_jpg.jpg"
                    />
                  </Link>
                  <h2 className="text-center xl-headline">
                    <Link to="/ocad-undergrad-thesis/orrery">Orrery</Link>
                  </h2>
                </div>
                <div className="col">
                  <Link to="/ocad-undergrad-thesis/gallery">
                    <img
                      className="img-fluid"
                      src="https://images.ctfassets.net/z5m1dcunobst/2JmO6p8W3FPxNl6IolO7ed/0b582a7bdd729492507a1302c4c09fb0/Orreretically_Speaking_4_16_2020_5_41_42_AM_2_.png"
                    />
                  </Link>
                  <h2 className="text-center xl-headline">
                    <Link to="/ocad-undergrad-thesis/gallery">Gallery</Link>
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <h2 className="xl-headline">Annotated Bibliography</h2>
                  <p>
                    <br />
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Bischoff, Bastian, and Per&nbsp;Emanuelsson. “Designing
                    Time.”&nbsp;Humans since 1982, Humans since 1982,
                    2018,&nbsp;
                    <a
                      href="http://www.humanssince1982.com/artist-statement-a-million-times"
                      data-link-type="external"
                      data-link-value="www.humanssince1982.com/artist-statement-a-million-times"
                      target="_blank"
                    >
                      www.humanssince1982.com/artist-statement-a-million-times
                    </a>
                    .&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Million Times
                    is a series of works that began as a typeface using the
                    hands and face of a clock. According to, Humans, the ethos
                    behind it was to “design time”. The work consists of
                    numerous mechanical clocks that are programmed to move in
                    various synchronous and asynchronous ways. Many iterations
                    have been created with the most known so far being the
                    installation in the Singapore Changi Airport in Terminal 2.
                    This installation is their largest yet, sporting 504
                    clock-faces, each playing a role in the larger display. This
                    artwork has also been very inspirational to my project in
                    showing how the mechanics and programming come together in
                    creating a larger display.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Bretagnon, P, and G&nbsp;Francou. “Planetary Theories in
                    Rectangular and Spherical Variables. VSOP87
                    Solutions.”&nbsp;Astronomy and Astrophysics, vol. 102, no.
                    1-2, Aug. 1988, pp. 309–315.,{" "}
                    <a
                      href="http://adsabs.harvard.edu/full/1988A%26A...202..309B"
                      data-link-type="external"
                      data-link-value="adsabs.harvard.edu/full/1988A%26A...202..309B"
                      target="_blank"
                    >
                      adsabs.harvard.edu/full/1988A%26A...202..309B
                    </a>
                    .&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp; &nbsp; &nbsp; &nbsp;There are several iterations of
                    the VSOP theories. These are solutions for the motion of the
                    eight planets in the solar system.&nbsp; These methods are
                    used in the field of&nbsp;astrometry and&nbsp;vary in levels
                    of accuracy over spans of time. For example, VSOP87 can be
                    used to accurately calculate the positions of planets for up
                    to several thousands of years before and after the year
                    2000. My project will use VSOP87 in its calculations made
                    available by an open source library called Astronomy Engine
                    (Cross).&nbsp; The
                    authors,&nbsp;Bretagnon&nbsp;and&nbsp;Francou&nbsp;explain
                    on page 309 that, “VSOP87 is an algorithm used to calculate
                    celestial objects using rectangular and elliptical
                    coordinates”. These points of reference exist across
                    multiple tables that are used in its algorithm to calculate
                    the most accurate 3D position possible in relation to a
                    specific time.&nbsp; In other words, the input is the date
                    (month, day, and year).&nbsp; The output is an (X, Y, Z)
                    position, which are the rectangular coordinates, and the
                    radial distance from the sun, the polar angle, and the
                    azimuthal angle, which are the spherical coordinates and are
                    all in relation to the sun.&nbsp; These spherical
                    coordinates are shown in the picture to the left.&nbsp;
                    Theta (θ) represents the polar angle (tilt on the z
                    axis).&nbsp; The variable&nbsp;r&nbsp;represents&nbsp;radial
                    distance and phi (𝛗) represents the azimuthal angle
                    (rotation on the&nbsp;XY&nbsp;plane) The solution includes
                    five deriving solutions that are used to maximize
                    precision.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Cross, Don. Astronomy Engine&nbsp;cosinekitty. astronomy,
                    commit 4eed90fd42206328980aa58d40cdabdfe7f4c05e, Don Cross,
                    Aug 16 2016.&nbsp;GitHub,&nbsp;
                    <a
                      href="https://github.com/cosinekitty/astronomy"
                      target="_blank"
                      data-link-type="external"
                      data-link-value="https://github.com/cosinekitty/astronomy"
                    >
                      https://github.com/cosinekitty/astronomy (Links to an
                      external site.)
                    </a>
                    &nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Don Cross, AKA
                    “cosinekitty” created the “AstronomyEngine”, which is an
                    open source library used to calculate the positions
                    of&nbsp;celestial bodies&nbsp;and predict the dates of
                    celestial events such as lunar phases, sun&nbsp;rise
                    and&nbsp;set times. This library uses VSOP87 which is one of
                    the earlier&nbsp;iterations&nbsp;of&nbsp;the analytical
                    planetary theory
                    Variations&nbsp;Séculaires&nbsp;des&nbsp;Orbites
                    Planétaires&nbsp;(VSOP). I use this library to retrieve
                    planetary information&nbsp;based&nbsp;on&nbsp;a date input
                    by the user into the mobile app. Although I have come
                    to&nbsp;learn and&nbsp;understand how to retrieve data using
                    the open source ephemerides that are available, it would
                    require me to create a&nbsp;retrieval library&nbsp;similar
                    to what has already been created by Cross. This has
                    allowed&nbsp;me to&nbsp;&nbsp;focus on building the
                    structure and companion app for my installation.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Flyte. “STORY - Set Time Free.”&nbsp;Flyte,{" "}
                    <a
                      href="http://flytestore.com/pages/story-levitating-timepiece"
                      data-link-type="external"
                      data-link-value="flytestore.com/pages/story-levitating-timepiece"
                      target="_blank"
                    >
                      flytestore.com/pages/story-levitating-timepiece
                    </a>
                    .&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Story is a
                    modernistic clock that features a magnetically levitating
                    sphere, backlit LEDs, and a companion app. Unlike a
                    conventional clock, the Story has no minute or hour hands,
                    but it does have afront-facing LED time display. The sphere
                    is used to illustrate a “journey "around the clock face. The
                    duration of this journey is completely customizable and can
                    denote any duration that the user sets using the companion
                    app. This product is an artistic and functional timepiece
                    that has inspired me to think outside the confines of how
                    time can be illustrated. With this product, the journey of
                    the sphere around the face has become contextual only to the
                    user and others who've been made aware of it. This timepiece
                    is relevant to my project because it is a successful example
                    of building an artistic product that has a companion-app
                    that allows for a personalized experience of time in
                    context.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Moisson, X. “Solar System Planetary Motion to Third Order of
                    the Masses.”&nbsp;Astronomy and Astrophysics, vol. 341,
                    1999, pp. 318–327.,{" "}
                    <a
                      href="http://adsabs.harvard.edu/full/1999A%26A...341..318M"
                      data-link-type="external"
                      data-link-value="adsabs.harvard.edu/full/1999A%26A...341..318M"
                      target="_blank"
                    >
                      adsabs.harvard.edu/full/1999A%26A...341..318M
                    </a>
                    .&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp; &nbsp; &nbsp; &nbsp;Moisson’s paper presents the
                    VSOP2000 theory which builds upon its predecessors, VSOP82
                    and VSOP 87. In detail, Moisson outlines how this iteration
                    of the theory uses&nbsp;collected data to incorporate the
                    minor deviations during the planets’ orbits due to the
                    gravitational attraction of&nbsp;neighbouring&nbsp;celestial
                    objects such as asteroids, comets,
                    and&nbsp;neighbouring&nbsp;planets. He also compares the
                    calculations of previous theory VSOP82 with collected data
                    DE403 that this updated theory is incorporating. This not
                    only validates the&nbsp;solution, but&nbsp;allows Moisson to
                    measure the improvement of accuracy. This accuracy is very
                    important to me. With&nbsp;rectangular and spherical
                    coordinates being determined based on a specific time, I can
                    position the planets in a configuration that replicates the
                    solution results. This would mean that my configurations can
                    then be verified for accuracy by testing it against&nbsp;
                    <a
                      href="https://solarsystem.nasa.gov/"
                      target="_blank"
                      data-link-type="external"
                      data-link-value="https://solarsystem.nasa.gov/"
                    >
                      NASA’s Eyes Program
                    </a>
                    . This interactive sculpture is not only meant to be
                    artistic, but also accurate.&nbsp;
                  </p>
                  <p className="Paragraph SCXW61390010 BCX0">
                    O'Brien, Tim. “Giant Clockwork Solar System Arrives at
                    Jodrell Bank.” Jodrell Bank Centre for Astrophysics News,
                    University of Manchester, 25 Mar. 2013, 10:29,{" "}
                    <a
                      href="http://www.jb.man.ac.uk/news/2013/Orrery/"
                      data-link-type="external"
                      data-link-value="www.jb.man.ac.uk/news/2013/Orrery/"
                      target="_blank"
                    >
                      www.jb.man.ac.uk/news/2013/Orrery/
                    </a>
                    .&nbsp;&nbsp;
                  </p>
                  <p className="Paragraph SCXW61390010 BCX0">
                    &nbsp; &nbsp; &nbsp; &nbsp; The Orrery at
                    the&nbsp;Jordell&nbsp;Bank Discovery Centre is one of the
                    few successfully suspended mechanical orreries. Measuring at
                    5m (16 ft.) in diameter it is also amongst the biggest in
                    the world. According to Teresa Anderson, Director of the
                    Discovery Centre, this installation took over two years in
                    planning and development and boasts a system of 52 gears
                    used to precisely align the movement of planets. This
                    installation is a beautiful example of precise engineering,
                    which I hope to incorporate into my prototype. My prototype
                    will not rely on a large amount of gears to replicate the
                    accuracy of position and movement, but will, rely on
                    technology to determine the position.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    Simon, J.-L., et al. “New Analytical Planetary Theories
                    VSOP2013 and TOP2013.”&nbsp;Astronomy &amp; Astrophysics,
                    vol. 557, 2013,&nbsp;doi:10.1051/0004-6361/201321843.&nbsp;
                  </p>
                  <p className="Paragraph SCXW52207344 BCX0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In this article,
                    Simon, et al, account the improvements they have made upon
                    the&nbsp;analytical planetary theories:
                    the&nbsp;Variations&nbsp;Séculaires&nbsp;des
                    Orbites&nbsp;Planétaires(VSOP)&nbsp;and the Theory of the
                    Outer Planets (TOP).&nbsp; The authors go into detail
                    explaining how the VSOP2010, VSOP2013, and TOP theories are
                    built upon the algorithms and derivations used in previous
                    theories and how they have been able to improve the accuracy
                    of the 2D and 3D coordinates of the planets locations in
                    relation to time by factoring in additional data gathered
                    through observation and analytical computing of other
                    celestial bodies disturb the planetary orbits. Although the
                    computed results of these algorithms are publicly available,
                    they’re somewhat large (2.5GB) and would take more time than
                    I have available to implement into the software I use for my
                    thesis project. This paper and the data made available
                    through it, are critical verifying the theoretical accuracy
                    of the planets that are in my model solar system. As my
                    prototype will only include the four most inner planets,
                    only VSOP is currently relevant to my project.
                  </p>
                  <p>
                    Staugaitis, Laura. “Sunlight Casts Shadows of Phrases
                    Exploring Theories of Time in a Street Art Installation by
                    DAKU.”{" "}
                    <i>
                      Sunlight Casts Shadows of Phrases Exploring Theories of
                      Time in a Street Art Installation by DAKU
                    </i>
                    , Colossal, 3 June 2019,
                    www.thisiscolossal.com/2019/01/theory-of-time-by-daku/.
                  </p>
                  <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; The artist, Daku created a
                    street artwork called, 'Theory of Time', which uses the
                    sunlight and its changing direction over time to cast
                    shadows of text along the street throughout the day. What
                    makes this artwork inspiring to me and the theory of my
                    project is it's use of sunlight, which is a cyclical affect
                    of heliocentric time to superimpose theories of time along
                    the street floor. Phrases such as "Time is fleeting" or
                    "Time moves."
                  </p>
                </div>
              </div>

              <hr />

              <div className="row hidden">
                <div className="col">
                  <h2 className="xl-headline">Special Thanks</h2>
                  <p>
                    {" "}
                    I would like to express my gratitude to those that have
                    contributed to the success&nbsp;of this project:
                    <br />
                  </p>
                  <ul>
                    <li>
                      My God, the depth of my inspiration and creativity.&nbsp;
                    </li>
                    <li>
                      My wife, best friend, and muse -&nbsp;&nbsp;Sabrina
                      Bain.&nbsp;
                    </li>
                    <li>
                      My&nbsp;father,&nbsp;mentor, and soundboard -&nbsp;Byron
                      V. Bain.&nbsp;
                    </li>
                    <li>
                      And the entire OCADU Digital Futures staff for their
                      insight&nbsp;and instruction
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <PostAuthorSection />
        </ContentSection>
      </ContentWrapper>
    </Layout>
  )
}

export default OcadUndergradThesisPage1
